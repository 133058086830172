import React from "react";
import "../card-style.css";
import { Helmet } from "react-helmet";

var data = require("./data.json");

export const CarlBourjeily = () => {
  const downloadContact = () => {
    // Replace 'path/to/your/contact.vcf' with the actual path or URL to your vCard file
    const vCardUrl = "assets/cards-contacts/" + data.vcard;

    // Create a link element
    const downloadLink = document.createElement("a");
    downloadLink.href = vCardUrl;

    // Specify the download attribute with a suggested filename
    downloadLink.download = data.vcard;

    // Append the link to the body
    document.body.appendChild(downloadLink);

    // Trigger a click on the link to start the download
    downloadLink.click();

    // Remove the link from the body
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <Helmet>
        <title>{data.userName}</title>
        <meta name="description" content="OnePage Pro simplifies your social media bio by creating a single, customizable landing page for all your links" />
      </Helmet>
      <section className="background-link-body" style={{ backgroundImage: `${data.gradient}` }}>
        <section className="links-body">
          <div className="link-page">
            {data.hasStarsBoolean ? (
              <div>
                <div id="stars1"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
              </div>
            ) : (
              <div id="height"></div>
            )}

            {data.isLogoTransparentBoolean ? (
              <img id="profilePictureTransparent" src={`assets/cards-profile/${data.logo}`} alt={`${data.userName} Profile`} />
            ) : (
              <img id="profilePicture" src={`assets/cards-profile/${data.logo}`} alt={`${data.userName} Profile`} />
            )}
            <div id="userName">{data.userName}</div>
            <div id="description">{data.description}</div>
            <div className="social-media-icons">
              {Object.keys(data.socials).map((key) => (
                <a href={`${data.socials[key]}`} target="_blank" rel="noopener noreferrer">
                  <i className={`${key}`}></i>
                </a>
              ))}
            </div>

            <div id="links">
              {data.vcard === "" ? (
                <div> </div>
              ) : (
                <btn className="link download" onClick={downloadContact}>
                  {data.vcardBtn}
                </btn>
              )}

              {Object.keys(data.links).map((key) => (
                <a className="link" href={`${data.links[key]}`} target="_blank" rel="noopener noreferrer">
                  {key}
                </a>
              ))}
            </div>
          </div>

          <footer className="footer footer-cards pt-0 pb-4 pb-lg-0">
            <a href="https://www.onepagepro.ca/">
              <img src="assets\img\OnePagePro-logo.png" alt="OnePage Pro logo" />
            </a>
          </footer>
        </section>
      </section>
    </>
  );
};
