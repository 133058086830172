import React from "react";
import { Routes, Route } from "react-router-dom";
import { AnthonyChraim } from "./components/cards/anthonychraim/link";
import { ChristianHamaoui } from "./components/cards/christianhamaoui/link";
import { SebastienHamaoui } from "./components/cards/sebastienhamaoui/link";
import { CarlBourjeily } from "./components/cards/carlbourjeily/link";
import { AndrewAbiGhanem } from "./components/cards/andrewabighanem/link";
import { ElieAbiGhanem } from "./components/cards/elieabighanem/link";
import { Example } from "./components/cards/example/link";
import { Hero } from "./components/home/hero";
import { JaydenChamoun } from "./components/cards/jaydenchamoun/link";
import { OnePagePro } from "./components/cards/onepagepro/link";
import { BestAutoBody } from "./components/cards/bestautobody/link";

const App = () => {
  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <>
              <Hero />
            </>
          }
        ></Route>
        <Route
          path="/404"
          element={
            <>
              <Hero />
            </>
          }
        ></Route>
        <Route
          path="/"
          element={
            <>
              <Hero />
            </>
          }
        ></Route>
        <Route
          path="/example-landing-page"
          element={
            <>
              <Example />
            </>
          }
        ></Route>
        <Route
          path="/anthonychraim"
          element={
            <>
              <AnthonyChraim />
            </>
          }
        ></Route>
        <Route
          path="/christianhamaoui"
          element={
            <>
              <ChristianHamaoui />
            </>
          }
        ></Route>
        <Route
          path="/sebastienhamaoui"
          element={
            <>
              <SebastienHamaoui />
            </>
          }
        ></Route>
        <Route
          path="/carlbourjeily"
          element={
            <>
              <CarlBourjeily />
            </>
          }
        ></Route>

        <Route
          path="/andrewabighanem"
          element={
            <>
              <AndrewAbiGhanem />
            </>
          }
        ></Route>
        <Route
          path="/elieabighanem"
          element={
            <>
              <ElieAbiGhanem />
            </>
          }
        ></Route>
        <Route
          path="/jaydenchamoun"
          element={
            <>
              <JaydenChamoun />
            </>
          }
        ></Route>
        <Route
          path="/onepagepro"
          element={
            <>
              <OnePagePro />
            </>
          }
        ></Route>
        <Route
          path="/bestautobody"
          element={
            <>
              <BestAutoBody />
            </>
          }
        ></Route>
      </Routes>
    </>
  );
};

export default App;
