import React from "react";
import { Helmet } from "react-helmet";
import "../cards/card-style.css";

export const Hero = () => {
  return (
    <>
      <Helmet>
        <title>OneCard Pro - Smart Business Cards</title>
        <meta name="description" content="Elevate your professional networking with our Smart Business Cards, designed to leave a lasting impression and enhance your business connections." />
      </Helmet>
      <section className="background-link-body-hero">
        <section className="links-body">
          <div className="link-page">
            <div>
              <div id="stars1"></div>
              <div id="stars2"></div>
              <div id="stars3"></div>
            </div>
            <div className="container">
              <h1 className="card-hero-title">
                Get Your <span className="text-gradient-primary">Smart Business Card</span> Now!
              </h1>
              <p className="card-hero-description">
                Our Smart Business Cart helps you connect better and work smarter. It's all about making your business interactions smoother and more professional.
              </p>
            </div>
            <div className="business-card-section">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front d-flex align-items-center justify-content-center"></div>
                  <div className="flip-card-back d-flex align-items-center justify-content-center"></div>
                </div>
              </div>
            </div>

            <div className="business-card-btn">
              <button className="cssbuttons-io">
                <a className="btn-link" href="https://www.onepagepro.ca/smart-card">
                  <span>Discover Our Cards!</span>
                </a>
              </button>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
